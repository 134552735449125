import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Text, useColorModeValue, } from "@chakra-ui/react";
import Card from "components/card/Card";
import { AppDispatch, RootState } from "../../../../redux/store";
import PieChart from "components/charts/PieChart";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import StrategyCalculator from "utils/StrategyCalculator";
import {
  resetSelectedStrategyIds,
  setActualValueState,
  setSelectedStrategyids
} from "../../../../redux/slices/dataSlice";

interface ActualValueProps {
}


const ActualValue: React.FC<ActualValueProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const actualValueStateRef = useRef<string[]>([])

  const selectedDate = useSelector(
    (state: RootState) => state.data.selectedDate
  );
  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );
  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );
  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );
  const actualValueState: string[] = useSelector(
    (state: RootState) => state.data.actualValueState,
  );

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
      }),
    []
  );

  const strategyCalculator = useMemo(
    () => new StrategyCalculator(strategyMappingSettings),
    [strategyMappingSettings]
  );

  const [strategyValues, setStrategyValues] = useState<{
    [key: string]: number;
  }>();

  const calculationDate = useMemo(() => {
    return selectedDate ? new Date(selectedDate) : new Date();
  }, [selectedDate])

  const pieChartData = useMemo(() => {
    const data: (string | number | object)[][] = [];
    data.push(["Strategy", "Actual Value", { role: "tooltip" }]);
    if (strategyValues) {
      Object.keys(strategyValues).forEach((strategyName) => {
        data.push([
          strategyName,
          strategyValues[strategyName],
          `${strategyName}: ${currencyFormatter.format(
            strategyValues[strategyName]
          )}`,
        ]);
      });
    }
    return data
  }, [currencyFormatter, strategyValues]);

  const getNewBreadcrumb = useCallback((strategyName: string) => {
    if (actualValueStateRef.current.length && actualValueStateRef.current[actualValueStateRef.current.length - 1] !== strategyName) {
      return [...actualValueStateRef.current, strategyName];
    }
    return actualValueStateRef.current;
  }, [])

  const handleSelectStrategyName = useCallback((strategyName: string) => {
    const newLevel = Math.min(actualValueStateRef.current.length, 2) // 2 is the maximum level, the min function will take the lowest number.

    console.log(actualValueStateRef.current);

    if (actualValueStateRef.current.length > 2) {
      return
    }

    const newBreadcrumb = getNewBreadcrumb(strategyName);

    if (newBreadcrumb) {
      dispatch(setActualValueState(newBreadcrumb));
    }
  }, [getNewBreadcrumb, dispatch]);

  const handleBreadcrumbClick = useCallback((label: string, index: number) => {
    if (index === 0) {
      dispatch(resetSelectedStrategyIds());
    } else {
      dispatch(setActualValueState([...actualValueStateRef.current.slice(0, index + 1)]))
    }
  }, []);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"))
  }, []);

  useEffect(() => {
    const strategyValues: { [key: string]: number } = {};

    stakeholders?.forEach((st) => {
      // Shares calculation
      st.shares?.forEach((share) => {
        if (!selectedStrategyIds.includes(share.strategyId)) {
          return;
        }
        const value = strategyCalculator.calculateActualShareValue(
          share,
          calculationDate
        );

        if (value) {
          let strategyName = "";
          switch (actualValueState.length) {
            case 1:
              strategyName = strategyCalculator.getStrategyNameById(
                share.strategyId
              );
              break;
            case 2:
              strategyName = strategyCalculator.getCombinedNameByStrategyId(
                share.strategyId
              );
              break;
            case 3:
              strategyName = strategyCalculator.getDetailedNameByStrategyId(
                share.strategyId
              );
              break;
          }

          if (strategyName) {
            if (!strategyValues[strategyName]) {
              strategyValues[strategyName] = 0;
            }
            strategyValues[strategyName] += value;
          }
        }
      });

      // we group the loans by strategy id, because there can be multiple loans which are actually still the same loan.
      // The same loans are indicated by the same strategy id.
      // For calculating the actual value, we group the loans and take the loan with the latest startDate.
      const actualLoans = _.chain(st.loans)
        .groupBy((x) => x.strategyId)
        .map((loans, strategyId) => ({
          strategyId,
          loan: _.maxBy(loans, (loan) => new Date(loan.startDate)),
        }))
        .value()
        .map((x) => x.loan);

      actualLoans?.forEach((loan) => {
        if (!selectedStrategyIds.includes(loan.strategyId)) {
          return;
        }
        const loanValue = strategyCalculator.calculateActualLoanValue(
          loan,
          calculationDate
        );

        if (loanValue) {
          let strategyName = "";
          switch (actualValueState.length) {
            case 1:
              strategyName = strategyCalculator.getStrategyNameById(
                loan.strategyId
              );
              break;
            case 2:
              strategyName = strategyCalculator.getCombinedNameByStrategyId(
                loan.strategyId
              );
              break;
            case 3:
              strategyName = strategyCalculator.getDetailedNameByStrategyId(
                loan.strategyId
              );
              break;
          }

          if (strategyName) {
            if (!strategyValues[strategyName]) {
              strategyValues[strategyName] = 0;
            }
            strategyValues[strategyName] += loanValue;
          }
        }
      });
    });

    setStrategyValues(strategyValues);

  }, [selectedStrategyIds, stakeholders, strategyCalculator, calculationDate, actualValueState]);

  useEffect(() => {
    if (!strategyMappingSettings || !actualValueState.length) {
      return
    }

    const lastStrategyIndex = actualValueState.length - 1;
    const lastStrategy = actualValueState[lastStrategyIndex];


    switch (lastStrategyIndex) {
      case 1: {
        const strategyDetail = strategyMappingSettings.values.find(
          (strategy) => strategy.strategy === lastStrategy
        );

        if (strategyDetail) {
          const newStrategyIds = strategyDetail.combined
            .flatMap((x) => x.values)
            .map((x) => x.entityId);
          dispatch(setSelectedStrategyids(newStrategyIds));
        }
        break
      }
      case 2: {
        const strategyDetail = strategyMappingSettings.values
          .flatMap((x) => x.combined)
          .find((combined) => combined.name === lastStrategy);

        if (strategyDetail) {
          const newStrategyIds = strategyDetail.values.map((x) => x.entityId);
          dispatch(setSelectedStrategyids(newStrategyIds));
        }

        break
      }
    }

  }, [dispatch, actualValueState, strategyMappingSettings]);

  useEffect(() => {
    actualValueStateRef.current = actualValueState
  }, [actualValueState]);

  return (
    <Card
      alignItems="center"
      flexDirection="column"
      w="100%"
      h="100%"
    >
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Actual Value
        </Text>
      </Flex>
      <PieChart
        chartData={pieChartData}
        onSelectStrategyName={handleSelectStrategyName}
      />
    </Card>
  );
};

export default ActualValue;
