/* eslint-disable */
// Chakra Imports
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Link, useColorModeValue,useBreakpointValue,Image } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import mainLogo from 'assets/img/logo_new_black_nobg.png';

interface Props {
  secondary: boolean;
  brandText: string;
}

export const NavBarDomId = 'NavBarAdminID'

export default function AdminNavbar({secondary, brandText}: Props) {
  const [scrolled, setScrolled] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false }); // Determine if it's a mobile view

  useEffect(() => {
    window.removeEventListener('scroll', changeNavbar);
    window.addEventListener('scroll', changeNavbar);

    return () => {
      window.removeEventListener('scroll', changeNavbar);
    };
  }, []);

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue('navy.700', 'white');
  let secondaryText = useColorModeValue('gray.700', 'white');
  let navbarPosition = 'fixed' as const;
  let navbarFilter = 'none';
  let navbarBackdrop = 'blur(20px)';
  let navbarShadow = 'none';
  let navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');
  let navbarBorder = 'transparent';
  let secondaryMargin = '0px';
  let paddingX = '0px';
  let gap = '0px';
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <Box
      position={navbarPosition}
      top={{ base: '12px', md: '16px', xl: '18px' }}
      boxShadow={scrolled ? 'md' : 'none'}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration="0.25s, 0.25s, 0.25s, 0s"
      transitionProperty="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: 'center' }}
      display={secondary ? 'block' : 'flex'}
      minH="75px"
      justifyContent={{ xl: 'center' }}
      lineHeight="25.6px"
      pb="8px"
      right={{ md: '30px', lg: '30px', xl: '30px' }}
      px={{
        sm: paddingX,
        md: '10px',
      }}
      ml={{
        sm: '12px',
      }}
      ps={{
        xl: '12px',
      }}
      pt="8px"
      w={{
        base: 'calc(100vw - 6%)',
        md: 'calc(100vw - 8%)',
        lg: 'calc(100vw - 6%)',
        xl: 'calc(100vw - 350px)',
        '2xl': 'calc(100vw - 365px)',
      }}
      zIndex={10}
      id={NavBarDomId}
    >
      <Flex
        w="100%"
        gap={{ sm: 0, md: '16px' }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={gap}
      >
        {/* Breadcrumb Section */}
        <Box
          flex="1"
          textAlign="left"
          m={{ sm: '8px', md: '0px' }}
        >
          {isMobile ? (
            <Image src={mainLogo} alt="Buysse & Partners" height="40px" />
          ) : (
            <Breadcrumb>
              <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
                <BreadcrumbLink href="/" color={secondaryText}>
                  Home
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem color={secondaryText} fontSize="sm">
                <BreadcrumbLink href={window.location.pathname} color={secondaryText}>
                  {brandText}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          )}
          <Link
            color={mainText}
            href="#"
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            fontSize="34px"
            display={{
              sm: 'none',
              md: 'unset',
            }}
            _hover={{ color: mainText }}
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          >
            {brandText}
          </Link>
        </Box>
  
        {/* Admin Navbar Links Section */}
        <Box
          flex="0 0 auto" // Ensure it takes only as much space as needed
          textAlign="right"
          whiteSpace="nowrap" // Prevent wrapping
          width="auto"
          minWidth="fit-content"
          ml="auto"
        >
          <AdminNavbarLinks secondary={secondary} />
        </Box>
      </Flex>
    </Box>
  );  
}



