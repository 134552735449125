import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import FilterBar from "./RoleEntity/FilterBar";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import routes from "routes/routes";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import FileUpload from "components/fileUpload/FileUpload";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { globalStyles } from "theme/styles";
import Impersonator from "./RoleEntity/Impersonator";

const AdminNavbarLinks = (props: { secondary: boolean }) => {
  const { secondary } = props;

  // Chakra Color Mode
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const { instance, accounts } = useMsal();
  const [fullName, setFullName] = useState<string>();

  const isAdmin = useSelector((state: RootState) =>
    state.data.usersByOriginalEmail.some((x) => x.isAdmin)
  );

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      let account = accounts[0];
      setFullName(account?.name);

      const storedAccount = sessionStorage.getItem("loggedInUser");
      if (storedAccount && storedAccount !== account.username) {
        // Clear session storage if a different user logs in
        sessionStorage.clear();
      }
      sessionStorage.setItem("loggedInUser", account.username);
    }
  }, [accounts]);

  const handleLogout = () => {
    sessionStorage.clear();
    instance.logout({ postLogoutRedirectUri: "/" });
  };

  return (
    <Flex
      w={{ sm: "auto", md: "100%" }}
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
      style={{ fontFamily: "Source Sans Pro" }}
    >
      <SidebarResponsive routes={routes} />
      <FilterBar />

      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            name={fullName}
            bg={globalStyles.customColors.flamePea}
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="md"
              fontWeight="700"
              color={textColor}
               textAlign="left"
            >
              {fullName}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <Impersonator loggedInEmail={""} />
          </Flex>
          <Flex flexDirection="column" pl="10px">
            {isAdmin ? <FileUpload /> : null}
            <MenuItem onClick={handleLogout}>
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default AdminNavbarLinks;

AdminNavbarLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
